import Skeleton from '@carbonfact/ui-components/src/Skeleton';
import {
  Combobox,
  ComboboxButton,
  ComboboxOptions,
  Transition,
} from '@headlessui/react';
import classNames from 'classnames';
import { useTranslations } from 'next-intl';
import { useCallback, useMemo, useState } from 'react';

import {
  DropdownOptionPrimitive,
  type DropdownOptionPrimitiveProps,
} from '../../primitives';
import { AutocompleteInputPrimitive } from '../../primitives/AutoCompleteInputPrimitive';
import { DropdownButtonPrimitive } from '../../primitives/DropdownButtonPrimitive';
import { DropdownOptionsScrollerPrimitive } from '../../primitives/DropdownOptionsScrollerPrimitive';
import { PlaceholderPrimitive } from '../../primitives/PlaceholderPrimitive';

type OptionType<T> = DropdownOptionPrimitiveProps<T>['option'];

export type DropdownDefaultProps<T extends React.Key = string> = {
  label?: string;
  options: OptionType<T>[];
  loading?: boolean;
  value: T | null;
  disabled?: boolean;
  backgroundColor?: string;
  onChange: (val: T) => void;
  className?: string;
  height?: string;
  placeholder?: string;
  maxDisplaySize?: number;
  autocomplete?: boolean;
  showArrow?: boolean;
  align?: 'left' | 'right';
};
export const Dropdown = <T extends React.Key = string>({
  label,
  options,
  disabled,
  backgroundColor,
  className = '',
  loading,
  placeholder,
  value,
  height = 'h-9',
  maxDisplaySize = 100,
  showArrow = true,
  onChange,
  autocomplete,
  align = 'left',
}: DropdownDefaultProps<T>) => {
  const t = useTranslations();
  const [autocompleteSearchTerm, setAutocompleteSearchTerm] = useState('');

  const [showOptions, setShowOptions] = useState(false);
  let bgColor = 'bg-white';

  if (backgroundColor) {
    bgColor = backgroundColor;
  }
  if (!placeholder) {
    placeholder = t('Dropdown.selectOption');
  }
  const handleChange = useCallback(
    (newPickedOption: OptionType<T>) => {
      onChange(newPickedOption?.value);
    },
    [onChange],
  );

  const { filteredOptions, filteredResultsLength } = useMemo(() => {
    if (!autocomplete) {
      return {
        filteredOptions: options,
        filteredResultsLength: options.length,
      };
    }

    let filteredOptions = options.filter((option) =>
      option.label.toLowerCase().includes(autocompleteSearchTerm.toLowerCase()),
    );

    const filteredResultsLength = filteredOptions.length;

    if (filteredOptions.length > maxDisplaySize) {
      filteredOptions = filteredOptions.slice(0, maxDisplaySize);
    }

    return { filteredOptions, filteredResultsLength };
  }, [options, autocomplete, autocompleteSearchTerm, maxDisplaySize]);

  const valueOption = options.find((anOption) => anOption.value === value);
  const isDisabled = disabled || options.length < 1;

  if (loading) {
    return <Skeleton height={height} width="min-w-[90px] w-full" />;
  }

  if (options.length === 0) {
    return null;
  }

  return (
    <Combobox
      disabled={isDisabled}
      value={valueOption || null}
      onChange={handleChange}
    >
      {() => (
        <>
          <div
            className={classNames(
              'relative border-carbon-100 w-fit transition-all',
              height,
              className,
              autocomplete && showOptions ? 'mr-[-0.5rem]' : '',
            )}
          >
            {(!autocomplete || (autocomplete && !showOptions)) && (
              <ComboboxButton
                className={classNames(
                  'rounded-md border-[1.5px] box-border border-gray-200 hover:border-gray-300 items-center px-2 focus:outline-none h-full w-full justify-between flex flex-row',
                  bgColor,
                )}
                onClick={() => {
                  setShowOptions(!showOptions);
                  setAutocompleteSearchTerm('');
                }}
              >
                <DropdownButtonPrimitive
                  showArrow={showArrow}
                  disabled={isDisabled}
                  prefix={label}
                >
                  {!value && <PlaceholderPrimitive text={placeholder} />}
                  {value && (
                    <p className="text-sm font-medium whitespace-nowrap overflow-hidden text-ellipsis">
                      {valueOption?.label}
                    </p>
                  )}
                </DropdownButtonPrimitive>
              </ComboboxButton>
            )}

            {showOptions && (
              <Transition
                leave="transition ease-in duration-100"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
                afterLeave={() => setShowOptions(false)}
              >
                <ComboboxOptions
                  static
                  className={classNames(
                    'absolute p-2 z-50 mb-20 min-w-full origin-top-right max-h-[50vh] overflow-y-auto rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none',
                    align === 'left' ? 'left-0' : 'right-0',
                    !autocomplete
                      ? 'absolute'
                      : 'relative top-[-0.5rem] left-[-0.5rem]',
                  )}
                >
                  {autocomplete && (
                    <AutocompleteInputPrimitive
                      placeholder={placeholder}
                      selectedText={valueOption?.label}
                      label={label}
                      onChange={setAutocompleteSearchTerm}
                    />
                  )}
                  {filteredOptions.length === 0 && (
                    <div className="p-1 text-sm text-carbon-500">
                      {t('Dropdown.noResultFound')}
                    </div>
                  )}
                  {autocomplete && filteredOptions.length > 0 && (
                    <div className="p-1 text-xs text-carbon-500 font-medium border-b border-carbon-100 flex pb-2">
                      {t('Dropdown.displayingXOptions', {
                        count: filteredOptions.length,
                        total: filteredResultsLength,
                      })}
                    </div>
                  )}
                  <DropdownOptionsScrollerPrimitive>
                    {filteredOptions.map((option) => (
                      <DropdownOptionPrimitive
                        key={option.value}
                        option={option}
                        selected={option.value === value}
                      >
                        {option.label}
                      </DropdownOptionPrimitive>
                    ))}
                  </DropdownOptionsScrollerPrimitive>
                </ComboboxOptions>
              </Transition>
            )}
          </div>
        </>
      )}
    </Combobox>
  );
};
